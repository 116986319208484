import { toBN } from 'web3-utils';

const isInt = num => {
  try {
    toBN(num);
    return true;
  } catch (e) {
    return false;
  }
};
const stringToArray = str => {
  return str.replace(/[^a-zA-Z0-9_,]+/g, '').split(',');
};

const MAIN_TOKEN_ADDRESS = '0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee';
// const MAIN_TOKEN_ADDRESS = '0xA25C76d4b51821667352EEc35dFB00472311267A';

const PWA_EVENTS = {
  PWA_UPDATED: 'PWA_UPDATED',
  PWA_MOUNT_ERROR: 'PWA_MOUNT_ERROR',
  PWA_UPDATE_FOUND: 'PWA_UPDATE_FOUND'
};

const compareAddress = (address1, address2) => {
  return address1.toLowerCase() == address2.toLowerCase()
}
export { isInt, stringToArray, compareAddress, MAIN_TOKEN_ADDRESS, PWA_EVENTS };
