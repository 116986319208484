<template>
  <v-row dense class="d-flex align-center justify-center ">
    <!--
    =====================================================================================
      Back Button:
      desktop position in a row on the left
      mobile position after the next button
    =====================================================================================
    -->
    <v-col
      v-if="backBtnMethod"
      cols="12"
      sm="6"
      order="last"
      order-sm="first"
      class="px-sm-2"
    >
      <mew-button
         :has-full-width="true"
        :title="backBtnText"
        :disabled="backDisable"
        btn-size="xlarge"
        @click.native="backBtnMethod()"
        class="btn_back"
      />
    </v-col>
    <!--
    =====================================================================================
      Next Button:
      desktop position in a row on the right
      mobile position before the back button
    =====================================================================================
    -->
    <v-col cols="12" :sm="backBtnMethod ? '6' : '6'" class="px-sm-2">
      <mew-button
        :has-full-width="true"
        btn-size="xlarge"
        :title="nextBtnText"
        :disabled="nextDisable"
        @click.native="nextBtnMethod()"
        class="btn_select"
      />
    </v-col>
  </v-row>
</template>
<script>
export default {
  name: 'AppBtnRow',
  props: {
    backBtnText: {
      type: String,
      default: function () {
        return this.$t("home.btn_back")
      }
    },
    backBtnMethod: {
      type: Function,
      default: function () {
        return {};
      }
    },
    backDisable: {
      type: Boolean,
      defualt: false
    },
    nextBtnText: {
      type: String,
      default: 'Next'
    },
    nextBtnMethod: {
      type: Function,
      default: function () {
        return {};
      }
    },
    nextDisable: {
      type: Boolean,
      defualt: false
    }
  }
};
</script>

<style lang="scss" scoped>
.btn_back, .btn_select{
  width: 100% !important;
}

@media(max-width: 768px){
  .btn_back{
    display: none;
  }
}
</style>