var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mew-component--mew-tools"},[_c('v-menu',{attrs:{"offset-y":"","close-on-click":"","bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"d-none d-lg-block btn-remove-style",staticStyle:{"height":"50px"},attrs:{"outlined":"","x-large":"","ripple":false}},'v-btn',attrs,false),on),[_c('mew-button',{staticClass:"px-2",attrs:{"color-theme":"primary","btn-size":"large","btn-style":"outline"}},[_c('div',{staticClass:"d-flex align-center"},[_c('img',{attrs:{"src":require("@/assets/images/icons/icon-grid-dot.png"),"alt":"Mew tools"}}),_c('div',{staticClass:"d-none d-md-block mew-label"},[_vm._v("MEW Hub")])])])],1),_c('v-btn',_vm._g(_vm._b({staticClass:"d-lg-none",staticStyle:{"height":"36px","min-width":"36px","padding":"0","border-radius":"10px"},attrs:{"color":"greenPrimary","outlined":""}},'v-btn',attrs,false),on),[_c('img',{attrs:{"src":require("@/assets/images/icons/icon-grid-dot.png"),"alt":"Mew Hub","height":"20"}})])]}}])},[_c('div',{staticClass:"pa-6 mew-tools-menu",class:[
        _vm.$vuetify.breakpoint.md ? 'md' : '',
        _vm.$vuetify.breakpoint.sm ? 'sm' : '',
        _vm.$vuetify.breakpoint.xs ? 'xs' : ''
      ]},[_c('v-row',_vm._l((_vm.tools),function(t,key){return _c('v-col',{key:key,staticClass:"text-center",attrs:{"cols":"6","sm":"4"}},[(t.link)?_c('a',{attrs:{"href":t.link,"target":"_blank"}},[_c('div',{staticClass:"tools-btn"},[_c('img',{attrs:{"src":t.img,"alt":t.label,"height":"50"}}),_c('h6',{staticClass:"mt-1 btn-lable basic--text"},[_vm._v(_vm._s(t.label))])])]):_vm._e()])}),1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }