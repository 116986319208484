var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mew-component--app-get-started"},[_c('div',{staticClass:"desktop-content d-none d-lg-block"},[_c('v-container',[_c('div',{staticClass:"d-flex align-center px-6"},[_c('div',[_c('h1',{staticClass:"white--text mb-3"},[_vm._v(" "+_vm._s(_vm.$t('home.get-started.heading'))+" ")]),_c('div',{staticClass:"mt-5",attrs:{"align":"left"}},[_c('a',{staticClass:"mr-1",attrs:{"href":_vm.isMobile()
                  ? 'https://apps.apple.com/app/id1464614025'
                  : 'https://mewwallet.com',"target":"_blank"}},[_c('img',{attrs:{"src":require("@/assets/images/icons/button-app-store.svg"),"alt":"Apple app store","height":"35"}})]),_c('a',{attrs:{"href":_vm.isMobile()
                  ? 'https://play.google.com/store/apps/details?id=com.myetherwallet.mewwallet'
                  : 'https://mewwallet.com',"target":"_blank"}},[_c('img',{attrs:{"src":require("@/assets/images/icons/button-play-store.svg"),"alt":"Google play store","height":"35"}})])])]),_c('v-spacer'),_c('div',{staticClass:"d-flex"},[_c('mew-button',{staticClass:"mr-4",attrs:{"color-theme":"primary","btn-style":"background","title":_vm.$t('home.get-started.button-text-one'),"btn-size":"xlarge"},nativeOn:{"click":function($event){return _vm.$router.push({ name: _vm.ROUTES_HOME.CREATE_WALLET.NAME })}}}),_c('mew-button',{attrs:{"color-theme":"primary","btn-style":"outline","title":_vm.$t('home.get-started.button-text-two'),"btn-size":"xlarge"},nativeOn:{"click":function($event){return _vm.$router.push({ name: _vm.ROUTES_HOME.ACCESS_WALLET.NAME })}}})],1)],1)])],1),_c('div',{staticClass:"mobile-content d-block d-lg-none"},[_c('v-container',{staticClass:"px-5"},[_c('div',{staticClass:"mx-auto",attrs:{"color":"transparent","max-width":"500px"}},[_c('div',{staticClass:"mb-9"},[_c('h1',{staticClass:"white--text"},[_vm._v(_vm._s(_vm.$t('home.get-started.heading')))]),_c('div',{staticClass:"mt-5",attrs:{"align":"left"}},[_c('a',{staticClass:"mr-1",attrs:{"href":_vm.isMobile()
                  ? 'https://apps.apple.com/app/id1464614025'
                  : 'https://mewwallet.com',"target":"_blank"}},[_c('img',{attrs:{"src":require("@/assets/images/icons/button-app-store.svg"),"alt":"Apple app store","height":"35"}})]),_c('a',{attrs:{"href":_vm.isMobile()
                  ? 'https://play.google.com/store/apps/details?id=com.myetherwallet.mewwallet'
                  : 'https://mewwallet.com',"target":"_blank"}},[_c('img',{attrs:{"src":require("@/assets/images/icons/button-play-store.svg"),"alt":"Google play store","height":"35"}})])])]),_c('v-row',[_c('v-col',{staticClass:"mr-n2 mb-n2",attrs:{"cols":"12","sm":"6"}},[_c('mew-button',{attrs:{"has-full-width":"","title":_vm.$t('home.get-started.button-text-one'),"btn-size":"xlarge"},nativeOn:{"click":function($event){return _vm.$router.push({ name: _vm.ROUTES_HOME.CREATE_WALLET.NAME })}}})],1),_c('v-col',{staticClass:"mb-n2",attrs:{"cols":"12","sm":"6"}},[_c('mew-button',{attrs:{"has-full-width":"","btn-style":"outline","title":_vm.$t('home.get-started.button-text-two'),"btn-size":"xlarge"},nativeOn:{"click":function($event){return _vm.$router.push({ name: _vm.ROUTES_HOME.ACCESS_WALLET.NAME })}}})],1)],1)],1)])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }