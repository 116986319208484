import { Toast, ERROR } from '@/modules/toast/handler/handlerToast';
// import { ETH, BSC, MATIC } from '@/utils/networks/types';
import { compareAddress } from '@/core/helpers/common';
import BigNumber from 'bignumber.js';
import {
  formatFiatValue,
  formatFloatingPointValue,
  formatIntegerValue
} from '@/core/helpers/numberFormatHelper';
import { toBN } from 'web3-utils';
// import getTokenInfo from '@/core/helpers/tokenInfo';
import abi from '@/modules/balance/handlers/abiERC20.js';
import abiLRPS from '@/modules/balance/handlers/abiERC_LRPS';
import { DEFAULT_TOKENS, MAIN_TOKENS } from '@/core/configs/defaultTokens';

const setCurrency = async function ({ commit }, val) {
  fetch('https://mainnet.mewwallet.dev/v2/prices/exchange-rates')
    .then(res => res.json())
    .then(rates => {
      const currentRate = rates
        ? rates.find(rate => rate.fiat_currency === val)
        : {};
      commit('SET_CURRENCY_RATE', {
        data: currentRate,
        timestamp: Date.now()
      });
    })
    .catch(e => {
      commit('SET_CURRENCY_RATE', {
        data: {},
        timestamp: Date.now()
      });
      Toast(e.message, {}, ERROR);
    });
};
const setLastPath = function ({ commit }, val) {
  commit('SET_LAST_PATH', val);
};
const setCoinGeckoTokens = function ({ commit }, params) {
  commit('SET_COIN_GECKO_TOKENS', params);
};
const setTokenAndEthBalance = async function ({
  rootGetters,
  dispatch,
  commit,
  rootState
}) {
  commit('wallet/SET_LOADING_WALLET_INFO', true, { root: true });
  const network = rootGetters['global/network'];
  const address = rootState.wallet.address;

  const _getTokenBalance = (balance, decimals) => {
    let n = new BigNumber(balance);
    if (decimals) {
      n = n.div(new BigNumber(10).pow(decimals));
      n = formatFloatingPointValue(n);
    } else {
      n = formatIntegerValue(n);
    }
    return n;
  };

  const setTokens = (token, balance, _lockAmount) => {
    const tokenConfig = {
      subtext: token.name,
      value: token.name,
      contract: token.contract,
      img: token.img,
      decimals: token.decimals,
      name: token.name,
      symbol: token.symbol,
      price: 0
    };

    const denominator = new BigNumber(10).pow(tokenConfig.decimals);
    const usdBalance = new BigNumber(balance)
      .div(denominator)
      .times(tokenConfig.price)
      .toString();

    const newTokenConfig = Object.assign(
      {
        balance,
        lockAmount: _getTokenBalance(_lockAmount, tokenConfig.decimals).value,
        balancef: _getTokenBalance(balance, tokenConfig.decimals).value,
        usdBalance: usdBalance,
        usdBalancef: formatFiatValue(usdBalance).value
      },
      tokenConfig
    );

    return newTokenConfig;
  };

  let tokens = [];
  let balanceMainToken = 0;
  let mainBalance = 0;
  let tokenInfo = {};

  for (let item of DEFAULT_TOKENS[network.type.chainID].tokens) {
    if (item.type) {
      let balance;
      let lockAmount;

      if (item.name == 'Fullerene') {
        const contract = new rootState.wallet.web3.eth.Contract(
          abi,
          item.contract
        );

        [balance, lockAmount] = await Promise.all([
          contract.methods.balanceOf(address).call(),
          contract.methods.lockedOfamount(address).call()
        ]);
      } else if (item.name == 'LRPS') {
        const contract = new rootState.wallet.web3.eth.Contract(
          abiLRPS,
          item.contract
        );

        [balance, lockAmount] = await Promise.all([
          contract.methods.balanceOf(address).call(),
          contract.methods.lockList(address).call()
        ]);
        lockAmount = lockAmount.lockAmount;
      }

      tokens.push(setTokens(item, balance, lockAmount));
      if (compareAddress(item.contract, MAIN_TOKENS[network.type.chainID])) {
        balanceMainToken = balance;
        tokenInfo = {
          symbol: item.symbol,
          img: item.img
        };
      }
    } else {
      mainBalance = await rootState.wallet.web3.eth.getBalance(address);

      tokens.push(setTokens(item, mainBalance));
      if (compareAddress(item.contract, MAIN_TOKENS[network.type.chainID])) {
        balanceMainToken = mainBalance;
        tokenInfo = {
          symbol: item.symbol,
          img: item.img,
          lockAmount: 'No'
        };
      }
    }
  }

  dispatch('wallet/setTokens', tokens, { root: true })
    .then(() => {
      dispatch('wallet/setAccountTokenInfo', tokenInfo, { root: true });

      dispatch('wallet/setAccountBalance', toBN(balanceMainToken), {
        root: true
      });
      dispatch('wallet/setAccountMainBalance', toBN(mainBalance), {
        root: true
      });
    })
    .then(() => {
      // dispatch can't be blank
      dispatch('custom/updateCustomTokenBalances', false, { root: true });
      commit('wallet/SET_LOADING_WALLET_INFO', false, { root: true });
    });
};
export default {
  setLastPath,
  setCurrency,
  setCoinGeckoTokens,
  setTokenAndEthBalance
};
