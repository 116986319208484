const customTokens = function (state, _, rootState, rootGetters) {
  const network = rootGetters['global/network'];
  const address = rootGetters['wallet/getAddress'];
  // const tokensC60 = {
  //   BSCT:[
  //     {
  //     address: address,
  //     balance: "0",
  //     balancef: "0",
  //     contract: "0x381ff9a05c63badac7dd8b031840e19032d0eae4",
  //     decimals: "18",
  //     name: "Fullerene Token",
  //     symbol: "C60",
  //     usdBalance: "0",
  //     usdBalancef: "$0.00"
  //   }
  //   ]
  // }

  // const customTokensByAddress = state.tokens[address] || tokensC60;
  const customTokensByAddress = state.tokens[address] || [];
  // const customTokensByAddress = tokensC60
  const tokens = customTokensByAddress[network.type.name] || [];

  const filterdTokens = tokens.filter(item => {
    return item.address === address;
  });

  return filterdTokens;
};

const hasCustom = function (state, _, rootState, rootGetters) {
  const tokens = rootGetters['custom/customTokens'];
  return tokens.length > 0;
  // return 1;
};

export default {
  customTokens,
  hasCustom
};
