var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mb-1 mew-component--claim-balance"},[_c('div',{staticClass:"mew-heading-2 mb-3"},[_vm._v(" "+_vm._s(_vm.$t('ens.claim-token-desc'))+" ")]),_c('div',{staticClass:"textLight--text mb-4"},[_vm._v(" "+_vm._s(_vm.$t('ens.claim.desc'))+" "),_c('a',{attrs:{"href":"https://ens.mirror.xyz/-eaqMv7XPikvXhvjbjzzPNLS4wzcQ8vdOgi9eNXeUuY","target":"_blank"}},[_vm._v(_vm._s(_vm.$t('common.learn-more'))+".")])]),_c('div',{staticClass:"border-container rounded-lg pa-5 mb-4 d-flex justify-space-between claim-border-container"},[_c('v-row',{staticClass:"align-center justify-start",attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('div',{class:[
            { 'mb-4': _vm.hasBalance },
            _vm.hasBalance ? 'greenPrimary--text' : 'bluePrimary--text',
            'font-weight-bold'
          ]},[_c('span',[_c('v-icon',{class:[
                _vm.hasBalance ? 'greenPrimary--text' : 'bluePrimary--text',
                'mr-1'
              ],attrs:{"size":"16px"}},[_vm._v(_vm._s(_vm.alertIcon))])],1),_vm._v(" "+_vm._s(_vm.eligibaleString)+" ")])]),(_vm.hasBalance)?_c('v-col',{staticClass:"d-flex align-center",attrs:{"cols":"12","sm":"4"}},[_c('div',{staticClass:"textLight--text"},[_vm._v(_vm._s(_vm.recivedString))])]):_vm._e(),(_vm.hasBalance)?_c('v-col',{attrs:{"cols":"12","sm":"8"}},[_c('v-row',{staticClass:"align-center justify-end mr-2"},[_c('img',{staticClass:"mr-2",attrs:{"src":"https://img.mewapi.io/?image=https://raw.githubusercontent.com/MyEtherWallet/ethereum-lists/master/src/icons/ENS-0xC18360217D8F7Ab5e7c516566761Ea12Ce7F9D72.png","height":"24","contain":""}}),_c('div',{staticClass:"mew-heading-2 textDark--text text-right"},[_vm._v(" "+_vm._s(_vm.ensTokenBalance)+" ")])])],1):_vm._e()],1)],1),(_vm.isEligable)?_c('div',[_c('div',{staticClass:"mew-heading-3 font-weight-bold mt-8 mb-2"},[_vm._v(" "+_vm._s(_vm.$t('ens.delegator.title'))+" ")]),_c('div',{staticClass:"textLight--text mb-4"},[_vm._v(" "+_vm._s(_vm.$t('ens.delegator.desc-one'))+" "),_c('a',{attrs:{"href":"https://sybil.org/#/delegates/ens","target":"_blank"}},[_vm._v(_vm._s(_vm.$t('common.here')))]),_vm._v(". "+_vm._s(_vm.$t('ens.delegator.desc-two'))+" "),_c('a',{attrs:{"href":"https://ens.mirror.xyz/cfvfKRpQSPtZJjPQOprWqEeqv2rytE7tQkxDg6ht7Oo","target":"_blank"}},[_vm._v(_vm._s(_vm.$t('common.here')))]),_vm._v(". ")])]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }