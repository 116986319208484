import bsc from '@/assets/images/networks/bsc.svg';
import eth from '@/assets/images/networks/eth.svg';
import matic from '@/assets/images/networks/matic.svg';

import c60 from '@/assets/images/tokens/c60.png';
import lrps from '@/assets/images/tokens/lrps.png';
const TOKEN_TYPE = {
    'NATIVE': 0,
    'ERC20': 1,
}

export const DEFAULT_TOKENS = {
    56: {
        tokens: [
            {
                name: 'Fullerene',
                symbol: 'C60',
                decimals: 18,
                type: TOKEN_TYPE.ERC20,
                contract: "0x548b47668d0b2d3587459a3a605ecf4e1fc2cde2",
                address: "0x548b47668d0b2d3587459a3a605ecf4e1fc2cde2",
                contract_address: "0x548b47668d0b2d3587459a3a605ecf4e1fc2cde2",
                icon: c60,
                img: c60,
                icon_png: c60,
                network: "bsc",
                website: "",
            },
            {
                name: 'LRPS',
                symbol: 'LRPS',
                decimals: 18,
                type: TOKEN_TYPE.ERC20,
                contract: "0x57f251706a6e4f5bb0A39eBaEb2335e3aF606057",
                address: "0x57f251706a6e4f5bb0A39eBaEb2335e3aF606057",
                contract_address: "0x57f251706a6e4f5bb0A39eBaEb2335e3aF606057",
                icon: lrps,
                icon_png: lrps,
                img: lrps,
                network: "bsct",
                website: "",
            },
            {   
                contract: "0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee",
                address: "0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee",
                contract_address: "0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee",
                icon: bsc,
                img: bsc,
                icon_png: bsc,
                network: "bsc",
                website: "",
                name: 'BNB',
                symbol: 'BNB',
                decimals: 18,
                type: TOKEN_TYPE.NATIVE
            }
        ]
    },
    97: {
        tokens: [
            {
                name: 'Fullerene',
                symbol: 'C60',
                decimals: 18,
                type: TOKEN_TYPE.ERC20,
                contract: "0xa25c76d4b51821667352eec35dfb00472311267a",
                address: "0xa25c76d4b51821667352eec35dfb00472311267a",
                contract_address: "0xa25c76d4b51821667352eec35dfb00472311267a",
                icon: c60,
                icon_png: c60,
                img: c60,
                network: "bsct",
                website: "",
            },
            {
                name: 'LRPS',
                symbol: 'LRPS',
                decimals: 18,
                type: TOKEN_TYPE.ERC20,
                contract: "0xD538a03806A13A6D5cA5BB09E0D9073b80376048",
                address: "0xD538a03806A13A6D5cA5BB09E0D9073b80376048",
                contract_address: "0xD538a03806A13A6D5cA5BB09E0D9073b80376048",
                icon: lrps,
                icon_png: lrps,
                img: lrps,
                network: "bsct",
                website: "",
            },
            {
                contract: "0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee",
                address: "0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee",
                contract_address: "0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee",
                icon: bsc,
                icon_png: bsc,
                img: bsc,
                network: "bsct",
                website: "",
                name: 'BNB',
                symbol: 'BNB',
                decimals: 18,
                type: TOKEN_TYPE.NATIVE
            }
        ]
    },
    1: {
        tokens: [
            {
                contract: "0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee",
                address: "0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee",
                contract_address: "0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee",
                icon: eth,
                icon_png: eth,
                img: eth,
                network: "eth",
                website: "",
                name: 'ETH',
                symbol: 'ETH',
                decimals: 18,
                type: TOKEN_TYPE.NATIVE
            }
        ]
    },
    137: {
        tokens: [
            {
                contract: "0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee",
                address: "0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee",
                contract_address: "0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee",
                icon: matic,
                icon_png: matic,
                img: matic,
                network: "MATIC",
                website: "",
                name: 'MATIC',
                symbol: 'MATIC',
                decimals: 18,
                type: TOKEN_TYPE.NATIVE
            }
        ]
    }
};

export const MAIN_TOKENS = {
    56: '0x548b47668d0b2d3587459a3a605ecf4e1fc2cde2',
    97: '0xa25c76d4b51821667352eec35dfb00472311267a',
    1: '0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee',
    137: '0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee',
}